@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.contact-us-page {
  margin-top: $navbar-mobile-height;
  // padding-top: 24px;
  padding-bottom: 24px;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: $navbar-height;
    // padding-top: 52px;
    padding-bottom: 52px;
  }

  section {
    position: relative;
  }

  .map {
    height: 493px;
    background-color: #fafafa;
  }

  .contact {
    padding: 40px 0 72px;
    position: relative;
    z-index: 1;

    .contact-address {
      .title {
        font-size: 46px;
      }
      .name {
        margin-top: 16px;
        padding: 8px 24px;
        color: #fff;
        background-color: $primary;
        font-size: 30px;
      }
      .subtitle {
        margin-top: 32px;
        color: $primary;
        font-size: 26px;
      }
      .address {
        margin-top: 8px;
        font-family: $reading-font;
        font-size: 26px;
      }
    }

    .contact-form {
      position: absolute;
      bottom: 0;

      width: 100%;
      padding: 40px 40px;
      background-color: #fff;
      border-radius: 24px;
      overflow: hidden;
      box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);

      > *:not(:first-child) {
        margin-top: 24px;
      }

      input[type='text'] {
        padding: 12px 16px;
        width: 100%;
        background-color: #fcfcfc;
        border: 1px solid #ebebeb;
        border-radius: 4px;
      }

      textarea {
        padding: 12px 16px;
        width: 100%;
        background-color: #fcfcfc;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        resize: none;
      }

      button {
        height: 54px;
        max-width: 246px;
        border-radius: 54px;
      }

      .send-btn {
        font-size: 20px;
      }
    }
  }

  .make-a-call {
    &__image {
      height: 570px;
      background-color: #fafafa;
      background-image: url(../images/phone-contact.jpg);
      background-position: center;
      background-size: cover;
    }
    &__info {
      padding: 72px 88px;
      display: flex;
      align-items: flex-end;
      color: #fff;
      background-color: $secondary;

      .title {
        font-size: 80px;
        line-height: 1.2;
      }
      .subtitle {
        font-size: 26px;
        font-weight: 300;
      }
      .phone {
        margin-top: 32px;
        font-size: 24px;
      }
      .email {
        font-size: 24px;
      }
    }
  }
}
