@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.client-page {
  margin-top: $navbar-mobile-height;
  padding-top: 24px;
  padding-bottom: 24px;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: $navbar-height;
    padding-top: 52px;
    padding-bottom: 52px;
  }

  section {
    position: relative;
  }

  .title {
    margin: 0;
    color: #4e4e4e;
    font-size: 32px;
    font-weight: normal;
    text-align: center;
  }

  .subtitle {
    margin: 0;
    margin-top: 24px;
    padding: 8px 24px;
    color: #fff;
    background-color: $primary;
    font-size: 24px;
    text-align: center;
  }

  .client-hero {
    &__image {
      height: 180px;
      // padding: 32px 0;
      background-color: #fafafa;
      background-image: url(../images/client-hero.jpg);
      background-position: center;
      background-size: cover;
      @media (min-width: map-get($grid-breakpoints, sm)) {
        height: 400px;
      }
    }
    &__items {
      margin-top: -56px;
      @media (min-width: map-get($grid-breakpoints, sm)) {
        margin-top: -80px;
      }
    }
    &__item {
      margin-bottom: 24px;
      background-color: #fff;
      border-radius: 24px;
      overflow: hidden;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);

      &__header {
        padding: 24px;
        color: $primary;
        text-align: center;
      }
      &__body {
        padding: 0 24px;
        font-family: $reading-font;
      }
      &__footer {
        padding: 24px;
      }
      &__cta-btn {
        height: 56px;
        border-radius: 16px;
        overflow: hidden;
      }
    }
  }
}
