@charset "UTF-8";
.main-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  height: 100px;
}
@media (min-width: 992px) {
  .main-header {
    height: 140px;
  }
}
.main-header .main-nav {
  transition: background-color 100ms ease-in-out;
  background-color: rgba(255, 255, 255, 0.65);
}
.main-header .navbar {
  flex: 1;
  padding: 12px 12px;
}
@media (min-width: 992px) {
  .main-header .navbar {
    align-items: center;
    padding: 16px 16px;
  }
}
.main-header .brand-logo {
  max-width: 128px;
}
@media (min-width: 992px) {
  .main-header .brand-logo {
    max-width: 256px;
  }
}
@media (max-width: 991px) {
  .main-header .navbar-nav {
    align-items: flex-start;
    overflow-y: auto;
    height: calc(100vh - 64px);
  }
}
@media (min-width: 992px) {
  .main-header .navbar-nav {
    justify-content: space-between;
  }
}
.main-header .navbar-nav .nav-link {
  color: #4e4e4e;
  font-size: 20px;
}
.main-header .nav-item {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}
@media (min-width: 992px) {
  .main-header .nav-item {
    flex: 1;
  }
}
@media (min-width: 992px) {
  .main-header .nav-item ul.wrapper {
    display: none;
  }
}
.main-header .nav-item ul.wrapper > li.active {
  border: 1px solid #2988c8;
  border-radius: 16px;
  padding: 0 8px;
}
.main-header .nav-item ul.wrapper a {
  color: #4e4e4e;
  text-decoration: none;
}
@media (min-width: 992px) {
  .main-header .nav-item:hover ul.wrapper {
    position: absolute;
    top: 100%;
    padding-top: 16px;
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;
  }
}
.main-header .nav-item.active::after {
  content: "";
  position: absolute;
  width: calc(100%);
  height: 3px;
  background-color: #ff4d02;
}
.main-header .nav-item.active .nav-link {
  color: #22598f;
}
.main-header .nav-item .nav-link {
  font-weight: 500;
  padding: 4px;
}
@media (min-width: 992px) {
  .main-header .nav-item .nav-link {
    padding: 4px 1rem;
  }
}
.main-header .nav-item .nav-link * {
  pointer-events: none;
}
.main-header .nav-item .nav-link.active .nav-link-active {
  visibility: visible;
}
.main-header .nav-item .nav-link .nav-link-active {
  display: none;
  visibility: hidden;
}
@media (min-width: 992px) {
  .main-header .nav-item .nav-link .nav-link-active {
    display: block;
  }
}
.main-header .sub-nav .wrapper {
  display: flex;
  flex-flow: row nowrap;
}
.main-header .sub-nav .wrapper > * {
  margin-right: 24px;
}
@media (min-width: 992px) {
  .main-header .nav-sub-item ul {
    display: none !important;
  }
  .main-header .nav-sub-item:hover ul {
    display: flex !important;
  }
}
.main-header .search-input {
  position: relative;
  display: none;
}
@media (min-width: 768px) {
  .main-header .search-input {
    display: block;
  }
}
.main-header .search-input input {
  height: 48px;
  width: 207px;
  padding: 16px;
  padding-right: 40px;
  margin: 8px 0;
  background-color: #fff;
  border: none;
  border-radius: 48px;
  font-weight: 300;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
}
.main-header .search-input::after {
  content: "";
  position: absolute;
  color: #999;
  font-family: "FontAwesome";
  right: 16px;
  top: 18px;
}
.main-header .nav-search {
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  .main-header .nav-search {
    display: flex;
  }
}
.main-header .nav-search__mobile-search-icon {
  margin-right: 8px;
  display: block;
  color: #999;
  font-size: 24px;
}
@media (min-width: 768px) {
  .main-header .nav-search__mobile-search-icon {
    display: none;
  }
}
.main-header .nav-search__inquiry {
  display: none;
  margin-left: 16px;
}
@media (min-width: 768px) {
  .main-header .nav-search__inquiry {
    display: block;
  }
}
.main-header .nav-search__inquiry-icon {
  margin-left: 8px;
  max-width: 24px;
}
.main-header .animated-icon1 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.main-header .animated-icon1 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  background: #4e4e4e;
}
.main-header .animated-icon1 span:nth-child(1) {
  top: 0;
}
.main-header .animated-icon1 span:nth-child(2) {
  top: 10px;
}
.main-header .animated-icon1 span:nth-child(3) {
  top: 20px;
}
.main-header .language-bar {
  height: 32px;
  padding: 4px 0;
  color: #fff;
  background-color: #4e4e4e;
}
.main-header .language-bar__user {
  cursor: pointer;
}
.main-header .language-bar__divider {
  margin-left: 16px;
  color: #fff;
}
.main-header .language-bar__language {
  margin-left: 16px;
}
.main-header .language-bar__language a {
  color: #fff;
}
.main-header .language-bar .container-fluid {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 576px) {
  .main-header .language-bar .container-fluid {
    justify-content: flex-end;
  }
}

.side-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -320px;
  width: 320px;
  padding: 32px;
  color: #fff;
  background-color: rgba(78, 78, 78, 0.93);
  z-index: 999;
  transition: left 0;
}
@media (min-width: 576px) {
  .side-menu {
    padding: 48px;
    left: -480px;
    width: 480px;
  }
}
.side-menu--open {
  left: 0;
  transition: left 0.1s ease-in-out;
}
.side-menu__close {
  font-size: 32px;
  text-align: right;
}
.side-menu__close-btn {
  cursor: pointer;
}
.side-menu__title {
  margin-top: 40px;
  color: #f4812e;
  font-size: 32px;
}
.side-menu .nav-item.nav-item-link {
  border-bottom: 2px solid #a2a2a2;
}
.side-menu .nav-item.nav-item-link.active {
  border-bottom: 4px solid #f4812e;
}
.side-menu .nav-item {
  margin-top: 24px;
}
@media (min-width: 576px) {
  .side-menu .nav-item {
    margin-top: 32px;
  }
}
.side-menu .nav-item .nav-link {
  font-size: 20px;
  color: #fff;
}
@media (min-width: 576px) {
  .side-menu .nav-item .nav-link {
    font-size: 24px;
  }
}
.side-menu__footer {
  margin-top: 52px;
  text-align: center;
}
.side-menu__footer .social {
  display: flex;
  justify-content: center;
}
.side-menu__footer .social a {
  color: #fff;
  padding: 14px;
}

.advice-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -320px;
  width: 320px;
  padding: 32px;
  color: #fff;
  background-color: #f4812e;
  z-index: 999;
  transition: right 0;
}
@media (min-width: 576px) {
  .advice-menu {
    padding: 48px;
    right: -480px;
    width: 480px;
  }
}
.advice-menu--open {
  right: 0;
  transition: right 0.1s ease-in-out;
}
.advice-menu__toggle-btn {
  cursor: pointer;
}
.advice-menu__close {
  font-size: 32px;
  text-align: left;
}
.advice-menu__close-btn {
  cursor: pointer;
}
.advice-menu__title {
  margin-top: 40px;
  color: #fff;
  font-size: 32px;
  text-align: center;
}
.advice-menu .contact-form {
  margin-top: 40px;
}
.advice-menu .contact-form > *:not(:first-child) {
  margin-top: 24px;
}
@media (min-width: 576px) {
  .advice-menu .contact-form > *:not(:first-child) {
    margin-top: 32px;
  }
}
.advice-menu .contact-form input[type=text] {
  padding: 12px 0;
  width: 100%;
  color: #333;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  outline: none;
  font-size: 20px;
}
@media (min-width: 576px) {
  .advice-menu .contact-form input[type=text] {
    font-size: 24px;
  }
}
.advice-menu .contact-form input[type=text]::placeholder {
  color: #fff;
}
.advice-menu .contact-form button {
  height: 54px;
  max-width: 246px;
  border-radius: 54px;
}
.advice-menu .contact-form .send-btn {
  font-size: 20px;
}
.advice-menu__footer {
  margin-top: 52px;
  color: #333;
  text-align: center;
}