.home-content section {
  position: relative;
}
.home-content .section {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.home-content .section__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-content .section__icon {
  width: 80px;
}
@media (min-width: 922px) {
  .home-content .section__icon {
    width: 150px;
  }
}
.home-content .section__title {
  margin-top: 16px;
  color: #fff;
  font-size: 24px;
  text-align: center;
}
@media (min-width: 922px) {
  .home-content .section__title {
    font-size: 50px;
  }
}
.home-content .section__subtitle {
  margin: 0;
  color: #fff;
  font-size: 24px;
}
.home-content .section.section--1 {
  background-image: url(../images/fig-1.jpg);
}
.home-content .section.section--2 {
  background-image: url(../images/fig-2.jpg);
}
.home-content .section.section--3 {
  background-image: url(../images/fig-3.jpg);
}
.home-content .section.section--4 {
  background-image: url(../images/fig-4.jpg);
}
.home-content .section.section--5 {
  background-image: url(../images/fig-5.jpg);
}
.home-content .contact-area {
  position: relative;
  top: 0;
  right: 60%;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  padding: 24px;
}
@media (min-width: 992px) {
  .home-content .contact-area {
    position: absolute;
    font-size: 22px;
    padding: 0;
  }
}
.home-content .footer-contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px 0;
}
.home-content .footer-contact > * {
  padding: 0 12px;
  margin: 4px 0;
  line-height: 1;
  white-space: nowrap;
}
.home-content .footer-contact > *:not(:last-child) {
  border-right: 1px solid #008dd1;
}