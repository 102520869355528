.login-modal .modal-content {
  position: relative;
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);
}
@media (min-width: 576px) {
  .login-modal .modal-content {
    padding: 32px;
  }
}
.login-modal .modal-body {
  padding: 0;
}
.login-modal__title {
  margin: 0;
  color: #4e4e4e;
  font-size: 32px;
  font-weight: normal;
  text-align: center;
}
.login-modal__subtitle {
  margin-top: 16px;
  padding: 8px 24px;
  min-height: 52px;
  color: #fff;
  background-color: #2988c8;
  font-size: 24px;
  text-align: center;
}
.login-modal__label {
  color: #787a7b;
  text-align: center;
}
.login-modal .input-form {
  width: 100%;
}
.login-modal .input-form > *:not(:first-child) {
  margin-top: 24px;
}
.login-modal .input-form input[type=text] {
  padding: 12px 16px;
  width: 100%;
  background-color: #fcfcfc;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}
.login-modal .login-btn {
  height: 52px;
  color: #fff;
  border-radius: 52px;
}
.login-modal .register-btn {
  height: 52px;
  max-width: 246px;
  color: #fff;
  border-radius: 52px;
}