.insurance-gauge {
  border-radius: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);
}
.insurance-gauge__header {
  min-height: 100px;
  padding: 24px;
  display: flex;
  align-items: flex-end;
  background-color: #2988c8;
}
.insurance-gauge__title {
  margin: 0;
  color: #fff;
}
.insurance-gauge__body {
  padding: 24px;
}
.insurance-gauge__body .progress {
  height: 12px;
  border-radius: 12px;
}
.insurance-gauge__gauge {
  margin-bottom: 24px;
  color: #f4812e;
}
.insurance-gauge__description {
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.insurance-gauge__footer {
  margin-top: auto;
  padding: 24px;
  padding-top: 0;
}
.insurance-gauge__footer .request-a-call-btn {
  height: 52px;
  border-radius: 14px;
}