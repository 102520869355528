.news-page {
  margin-top: 100px;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 992px) {
  .news-page {
    margin-top: 140px;
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
.news-page section {
  position: relative;
}
.news-page .news__title-wrapper {
  display: flex;
}
.news-page .news__title-bar {
  margin-left: 32px;
  background-color: #2988c8;
  flex: 1;
}
.news-page .news__title {
  font-size: 44px;
  text-align: center;
}
.news-page .news .articles2 {
  margin-top: 52px;
}
.news-page .news .articles2 .article2 {
  margin-bottom: 52px;
}
.news-page .news .articles2 .article2__info__title {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 500;
}
.news-page .news .articles2 .article2__info__title a {
  color: inherit;
}
.news-page .news .articles2 .article2__info__description {
  margin-top: 16px;
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.news-page .news .articles2 .article2__info__action {
  margin-top: 24px;
}
.news-page .more-btn {
  max-width: 176px;
  height: 63px;
  border-radius: 16px;
  font-size: 22px;
}