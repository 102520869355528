@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.news-page {
  margin-top: $navbar-mobile-height;
  padding-top: 24px;
  padding-bottom: 24px;

  @media (min-width: 992px) {
    margin-top: $navbar-height;
    padding-top: 52px;
    padding-bottom: 52px;
  }

  section {
    position: relative;
  }

  .news {
    // padding: 80px 0;

    &__title-wrapper {
      display: flex;
    }

    &__title-bar {
      margin-left: 32px;
      background-color: $primary;
      flex: 1;
    }

    &__title {
      font-size: 44px;
      text-align: center;
    }

    .articles2 {
      margin-top: 52px;
      // padding: 0;

      .article2 {
        margin-bottom: 52px;
        .figure {
        }

        &__info {
          &__title {
            margin-top: 24px;
            font-size: 24px;
            font-weight: 500;
            a {
              color: inherit;
            }
          }
          &__description {
            margin-top: 16px;
            font-family: $reading-font;
          }
          &__action {
            margin-top: 24px;
            // color: $secondary;
          }
        }
      }
    }
  }

  .more-btn {
    max-width: 176px;
    height: 63px;
    border-radius: 16px;
    font-size: 22px;
  }
}
