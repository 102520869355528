@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.hero {
  height: 90vh;
  max-height: 992px;
  padding: 32px 0;
  background-color: #fafafa;
  // background-image: url(../images/hero.jpg);
  background-position: center;
  background-size: cover;

  .hero-slide {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    letter-spacing: 0;
    width: 100%;
    height: 100%;

    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    .hero-item {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .title {
    color: #fff;
    font-size: 40px;
    font-weight: 500;
    @media (min-width: 768px) {
      font-size: 64px;
    }
    @media (min-width: 992px) {
      font-size: 100px;
    }
  }

  .subtitle {
    margin-top: 16px;
    padding: 24px;
    background-color: rgba(141, 204, 233, 0.9);
    box-shadow: 0 6px 36px rgba(0, 0, 0, 0.16);
    font-size: 16px;
    font-weight: 300;
    @media (min-width: 768px) {
      font-size: 18px;
    }
    @media (min-width: 992px) {
      font-size: 22px;
    }
  }

  .more {
    margin-top: 27px;
    margin-bottom: 8px;
    color: #fff;
    font-size: 21px;
    text-transform: uppercase;
    text-align: center;
  }
}
