@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.review {
  margin-top: 80px;

  &__figure {
    height: 100%;
    min-height: 480px;
    background-color: #fafafa;
    background-image: url(../images/review.jpg);
    background-position: center;
    background-size: cover;
  }
  &__label {
    margin: 0 -15px;
    padding: 8px 32px;
    color: #fff;
    background-color: $primary;
    font-size: 30px;
    text-transform: uppercase;
  }
  &__info {
    &__title {
      padding: 48px;
      font-size: 46px;
    }
    &__description {
      padding: 48px;
      font-size: 28px;
      font-family: $reading-font;
    }
  }
}
