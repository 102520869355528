.cursor-pointer {
  cursor: pointer;
}

.text-shadow {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border: 8px solid #e9ecef;
  border-radius: 200px;
}
.icon-wrapper--primary-bg {
  background-color: #2988c8;
}

.icon-small-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border: 2px solid #e9ecef;
  border-radius: 200px;
}
.icon-small-wrapper--primary-bg {
  background-color: #2988c8;
}

.nowrap {
  white-space: nowrap;
}