.partner-page {
  margin-top: 100px;
  padding-bottom: 24px;
}
@media (min-width: 992px) {
  .partner-page {
    margin-top: 140px;
    padding-bottom: 52px;
  }
}
.partner-page section {
  position: relative;
}
.partner-page .title {
  margin: 0;
  color: #4e4e4e;
  font-size: 32px;
  font-weight: normal;
  text-align: center;
}
.partner-page .subtitle {
  margin: 0;
  margin-top: 24px;
  padding: 8px 24px;
  min-height: 52px;
  color: #fff;
  background-color: #2988c8;
  font-size: 24px;
  text-align: center;
}

.partner-hero__image {
  height: 100px;
  background-color: #fafafa;
  background-image: url(../images/partner-hero.jpg);
  background-position: center;
  background-size: cover;
}
@media (min-width: 576px) {
  .partner-hero__image {
    height: 200px;
  }
}
.partner-hero__items {
  margin-top: -56px;
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);
}
@media (min-width: 576px) {
  .partner-hero__items {
    margin-top: -120px;
    padding: 40px;
  }
}
.partner-hero__item {
  margin-bottom: 24px;
  flex: 1;
  align-self: flex-end;
}
.partner-hero__item__header {
  padding: 24px;
  text-align: center;
}
.partner-hero__item__figure img {
  max-width: 100%;
  height: auto;
}
.partner-hero__item__body {
  padding: 24px;
  text-align: center;
  text-transform: uppercase;
}