.manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}
.manager__figure {
  max-width: 382px;
  border-radius: 400px;
  overflow: hidden;
}
.manager__figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.manager__body {
  padding: 24px;
  max-width: 382px;
}
.manager__body .progress {
  height: 12px;
  border-radius: 12px;
}
.manager__name {
  margin: 0;
  color: #2988c8;
  font-size: 24px;
  text-align: center;
}
.manager__title {
  font-size: 28px;
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  text-align: center;
}
.manager__description {
  text-align: center;
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.manager__footer {
  padding: 24px;
}
.manager__footer .request-a-call-btn {
  height: 52px;
  border-radius: 14px;
}