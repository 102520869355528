.promotions {
  padding: 100px 0;
  background-color: #fafafa;
  background-image: url(../images/promotion.jpg);
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
}
.promotions__title {
  color: #fff;
  font-size: 44px;
}
.promotions .promotions-slide {
  margin-top: 8px;
  margin-left: -32px;
  margin-right: -32px;
}
.promotions .promotion {
  margin: 32px 32px;
  display: flex;
  background-color: #fff;
  border-radius: 32px;
  box-shadow: 0 6px 26px rgba(0, 0, 0, 0.16);
}
.promotions .promotion .info {
  display: flex;
  flex-direction: column;
  padding: 48px;
  flex: 0 0 400px;
}
.promotions .promotion .info__title {
  font-size: 24px;
}
.promotions .promotion .info__subtitle {
  margin-top: 16px;
  color: #2988c8;
  font-size: 22px;
  line-height: 1.2;
}
.promotions .promotion .info__description {
  margin-top: 16px;
  font-size: 18px;
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  flex: 1 1 auto;
}
.promotions .promotion .info__action {
  flex: 0 0;
}
.promotions .promotion .figure {
  padding: 0 48px 48px 0;
}