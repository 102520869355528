.articles {
  padding: 80px 0;
  overflow-x: hidden;
}
.articles__title {
  font-size: 44px;
  text-align: center;
}
.articles .articles-slide {
  margin-top: 48px;
}
.articles .articles-slide .article {
  margin: 0 24px;
}
.articles .articles-slide .article__info__title {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 500;
}
.articles .articles-slide .article__info__description {
  margin-top: 16px;
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.articles .articles-slide .article__info__action {
  margin-top: 24px;
}