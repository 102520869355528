.insurances {
  position: relative;
  margin: -40px 0;
}
.insurances__inner {
  padding: 40px 0;
  overflow: hidden;
}
.insurances .insurance {
  height: 100%;
  position: relative;
  transition: transform ease-in-out 0.25s;
  transform: scale(1);
  z-index: 1;
}
.insurances .insurance:hover {
  transform: scale(1.2);
  z-index: 2;
}
.insurances .insurance:hover .info {
  background-color: #f4812e;
}
.insurances .insurance:hover .info .subtitle {
  display: block;
}
.insurances .insurance .figure {
  height: 100%;
}
.insurances .insurance .figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.insurances .insurance .info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 24px 40px;
  color: #fff;
  background-color: rgba(41, 136, 200, 0.86);
  font-size: 24px;
  line-height: 1.2;
  z-index: 1;
}
.insurances .insurance .info .subtitle {
  display: none;
  margin-top: 4px;
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
}