@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.partner-page {
  margin-top: $navbar-mobile-height;
  // padding-top: 24px;
  padding-bottom: 24px;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: $navbar-height;
    // padding-top: 52px;
    padding-bottom: 52px;
  }

  section {
    position: relative;
  }

  .title {
    margin: 0;
    color: #4e4e4e;
    font-size: 32px;
    font-weight: normal;
    text-align: center;
  }

  .subtitle {
    margin: 0;
    margin-top: 24px;
    padding: 8px 24px;
    min-height: 52px;
    color: #fff;
    background-color: $primary;
    font-size: 24px;
    text-align: center;
  }
}

.partner-hero {
  &__image {
    height: 100px;
    // padding: 32px 0;
    background-color: #fafafa;
    background-image: url(../images/partner-hero.jpg);
    background-position: center;
    background-size: cover;
    @media (min-width: map-get($grid-breakpoints, sm)) {
      height: 200px;
    }
  }
  &__items {
    margin-top: -56px;
    padding: 24px;
    background-color: #fff;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);
    @media (min-width: map-get($grid-breakpoints, sm)) {
      margin-top: -120px;
      padding: 40px;
    }
  }
  &__item {
    margin-bottom: 24px;
    flex: 1;
    align-self: flex-end;

    &__header {
      padding: 24px;
      text-align: center;
    }
    &__figure {
      img {
        max-width: 100%;
        height: auto;
        // height: 100%;
        // object-fit: cover;
      }
    }
    &__body {
      padding: 24px;
      // font-family: $reading-font;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
