@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  &__figure {
    max-width: 382px;
    border-radius: 400px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__body {
    padding: 24px;
    max-width: 382px;
    .progress {
      height: 12px;
      border-radius: 12px;
    }
  }
  &__name {
    margin: 0;
    color: $primary;
    font-size: 24px;
    text-align: center;
  }
  &__title {
    font-size: 28px;
    font-family: $reading-font;
    font-weight: 700;
    text-align: center;
  }
  &__description {
    // margin: 0;
    text-align: center;
    font-family: $reading-font;
  }
  &__footer {
    // margin-top: 24px;
    padding: 24px;
    .request-a-call-btn {
      height: 52px;
      border-radius: 14px;
    }
  }
}
