@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.main-footer {
  padding: 56px 0;
  font-size: 16px;
  font-weight: 300;

  &__address {
    margin-left: 44px;
  }

  &__nav {
    // margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    > *:not(:last-child) {
      margin-right: 8px;
    }

    // &__item {
    //   text-align: center;
    // }
  }

  &__contact {
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    // &__item {
    //   text-align: center;
    // }
  }

  .social {
    display: flex;

    a {
      color: #202020;
      padding: 14px;
      background-color: #ebebeb;
    }
  }
}
