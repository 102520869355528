@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.cookie-modal {
  padding: 24px 24px;
  width: 100%;
  max-width: 500px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;

  &__inner {
    padding: 24px;
    background-color: #fff;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }

  &__header {
    margin-bottom: 16px;
    display: flex;

    .close {
      margin-left: auto;
    }
  }
  &__title {
    margin: 0;
    color: $primary;
    font-size: 24px;
  }
  &__description {
    font-weight: 300;
  }

  &__footer {
    .btn {
      border-radius: 32px;
    }
  }
}
