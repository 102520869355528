@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.milestone {
  position: relative;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: map-get($grid-breakpoints, sm)) {
    padding-top: 96px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    border: 1px solid #c4c4c4;
  }

  &__1 {
    margin-top: 24px;
    margin-right: 24px;
    font-size: 14px;
    text-align: right;
    flex: 1;
    // order: 2;
    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-top: 60px;
      font-size: 20px;
      order: 1;
    }
  }
  &__2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-basis: 100%;
    order: -1;
    @media (min-width: map-get($grid-breakpoints, md)) {
      flex-basis: auto;
      order: 2;
    }
    .milestone__circle {
      margin: 0 auto;
      width: 128px;
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 128px;
      overflow: hidden;
      // flex: 0 0 193px;
      z-index: 1;
      @media (min-width: map-get($grid-breakpoints, md)) {
        width: 193px;
        height: 193px;
        border-radius: 193px;
      }
      &--color-1 {
        background-color: $primary;
      }
      &--color-2 {
        background-color: $secondary;
      }

      .title {
        margin: 0;
        color: #fff;
        font-size: 32px;
        text-align: center;
        @media (min-width: map-get($grid-breakpoints, md)) {
          font-size: 48px;
        }
      }
      .subtitle {
        margin: 0;
        color: #fff;
        font-size: 24px;
        text-align: center;
        @media (min-width: map-get($grid-breakpoints, md)) {
          font-size: 32px;
        }
      }
    }
  }
  &__3 {
    margin-top: 24px;
    margin-left: 24px;
    font-size: 14px;
    text-align: left;
    flex: 1;
    // order: 3;
    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-top: 60px;
      font-size: 20px;
      order: 3;
    }
  }

  &-start {
    margin: 0 auto;
    width: 32px;
    height: 32px;
    background-color: #c4c4c4;
    border-radius: 32px;
    overflow: hidden;
  }

  &-stop {
    margin: 0 auto;
    margin-top: 24px;
    width: 32px;
    height: 32px;
    background-color: #c4c4c4;
    border-radius: 32px;
    overflow: hidden;
    @media (min-width: map-get($grid-breakpoints, sm)) {
      margin-top: 96px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      border: 1px solid #c4c4c4;
    }
  }
}
