@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.subscribe {
  padding: 72px 0;
  background-color: #fafafa;
  background-image: url(../images/subscribe.jpg);
  background-position: center;
  background-size: cover;

  &__title {
    color: #fff;
    font-size: 44px;
    text-align: center;
  }

  &__email-input {
    padding: 24px 36px;
    margin-top: 48px;
    max-width: 935px;
    font-size: 22px;
    border: none;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.16);
  }

  &__subscribe-btn {
    margin-top: 64px;
    max-width: 356px;
    text-transform: uppercase;
    border-radius: 44px;
    font-size: 20px;
    font-weight: 500;
  }
}
