.contact-us-page {
  margin-top: 100px;
  padding-bottom: 24px;
}
@media (min-width: 992px) {
  .contact-us-page {
    margin-top: 140px;
    padding-bottom: 52px;
  }
}
.contact-us-page section {
  position: relative;
}
.contact-us-page .map {
  height: 493px;
  background-color: #fafafa;
}
.contact-us-page .contact {
  padding: 40px 0 72px;
  position: relative;
  z-index: 1;
}
.contact-us-page .contact .contact-address .title {
  font-size: 46px;
}
.contact-us-page .contact .contact-address .name {
  margin-top: 16px;
  padding: 8px 24px;
  color: #fff;
  background-color: #2988c8;
  font-size: 30px;
}
.contact-us-page .contact .contact-address .subtitle {
  margin-top: 32px;
  color: #2988c8;
  font-size: 26px;
}
.contact-us-page .contact .contact-address .address {
  margin-top: 8px;
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 26px;
}
.contact-us-page .contact .contact-form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 40px 40px;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
}
.contact-us-page .contact .contact-form > *:not(:first-child) {
  margin-top: 24px;
}
.contact-us-page .contact .contact-form input[type=text] {
  padding: 12px 16px;
  width: 100%;
  background-color: #fcfcfc;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}
.contact-us-page .contact .contact-form textarea {
  padding: 12px 16px;
  width: 100%;
  background-color: #fcfcfc;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  resize: none;
}
.contact-us-page .contact .contact-form button {
  height: 54px;
  max-width: 246px;
  border-radius: 54px;
}
.contact-us-page .contact .contact-form .send-btn {
  font-size: 20px;
}
.contact-us-page .make-a-call__image {
  height: 570px;
  background-color: #fafafa;
  background-image: url(../images/phone-contact.jpg);
  background-position: center;
  background-size: cover;
}
.contact-us-page .make-a-call__info {
  padding: 72px 88px;
  display: flex;
  align-items: flex-end;
  color: #fff;
  background-color: #f4812e;
}
.contact-us-page .make-a-call__info .title {
  font-size: 80px;
  line-height: 1.2;
}
.contact-us-page .make-a-call__info .subtitle {
  font-size: 26px;
  font-weight: 300;
}
.contact-us-page .make-a-call__info .phone {
  margin-top: 32px;
  font-size: 24px;
}
.contact-us-page .make-a-call__info .email {
  font-size: 24px;
}