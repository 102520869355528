@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.login-modal {
  .modal-content {
    position: relative;
    padding: 24px;
    background-color: #fff;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);
    @media (min-width: map-get($grid-breakpoints, sm)) {
      padding: 32px;
    }
  }
  .modal-body {
    padding: 0;
  }

  &__title {
    margin: 0;
    color: #4e4e4e;
    font-size: 32px;
    font-weight: normal;
    text-align: center;
  }
  &__subtitle {
    // margin: 0;
    margin-top: 16px;
    padding: 8px 24px;
    min-height: 52px;
    color: #fff;
    background-color: $primary;
    font-size: 24px;
    text-align: center;
  }
  &__label {
    color: #787a7b;
    text-align: center;
  }

  .input-form {
    width: 100%;

    > *:not(:first-child) {
      margin-top: 24px;
    }

    input[type='text'] {
      padding: 12px 16px;
      width: 100%;
      background-color: #fcfcfc;
      border: 1px solid #ebebeb;
      border-radius: 4px;
    }
  }
  .login-btn {
    height: 52px;
    color: #fff;
    border-radius: 52px;
  }
  .register-btn {
    height: 52px;
    max-width: 246px;
    color: #fff;
    border-radius: 52px;
  }
}
