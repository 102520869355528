.client-page {
  margin-top: 100px;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 992px) {
  .client-page {
    margin-top: 140px;
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
.client-page section {
  position: relative;
}
.client-page .title {
  margin: 0;
  color: #4e4e4e;
  font-size: 32px;
  font-weight: normal;
  text-align: center;
}
.client-page .subtitle {
  margin: 0;
  margin-top: 24px;
  padding: 8px 24px;
  color: #fff;
  background-color: #2988c8;
  font-size: 24px;
  text-align: center;
}
.client-page .client-hero__image {
  height: 180px;
  background-color: #fafafa;
  background-image: url(../images/client-hero.jpg);
  background-position: center;
  background-size: cover;
}
@media (min-width: 576px) {
  .client-page .client-hero__image {
    height: 400px;
  }
}
.client-page .client-hero__items {
  margin-top: -56px;
}
@media (min-width: 576px) {
  .client-page .client-hero__items {
    margin-top: -80px;
  }
}
.client-page .client-hero__item {
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);
}
.client-page .client-hero__item__header {
  padding: 24px;
  color: #2988c8;
  text-align: center;
}
.client-page .client-hero__item__body {
  padding: 0 24px;
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.client-page .client-hero__item__footer {
  padding: 24px;
}
.client-page .client-hero__item__cta-btn {
  height: 56px;
  border-radius: 16px;
  overflow: hidden;
}