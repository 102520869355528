.main-footer {
  padding: 56px 0;
  font-size: 16px;
  font-weight: 300;
}
.main-footer__address {
  margin-left: 44px;
}
.main-footer__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}
.main-footer__nav > *:not(:last-child) {
  margin-right: 8px;
}
.main-footer__contact {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}
.main-footer .social {
  display: flex;
}
.main-footer .social a {
  color: #202020;
  padding: 14px;
  background-color: #ebebeb;
}