@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.insurance-gauge {
  // margin-bottom: 24px;
  border-radius: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);

  &__header {
    min-height: 100px;
    padding: 24px;
    display: flex;
    align-items: flex-end;
    background-color: $primary;
  }
  &__title {
    margin: 0;
    color: #fff;
  }
  &__body {
    padding: 24px;
    .progress {
      height: 12px;
      border-radius: 12px;
    }
  }
  &__gauge {
    margin-bottom: 24px;
    color: $secondary;
  }
  &__description {
    // margin: 0;
    font-family: $reading-font;
  }
  &__footer {
    // margin-top: 24px;
    margin-top: auto;
    padding: 24px;
    padding-top: 0;
    .request-a-call-btn {
      height: 52px;
      border-radius: 14px;
    }
  }
}
