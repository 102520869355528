.cookie-modal {
  padding: 24px 24px;
  width: 100%;
  max-width: 500px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
}
.cookie-modal__inner {
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
.cookie-modal__header {
  margin-bottom: 16px;
  display: flex;
}
.cookie-modal__header .close {
  margin-left: auto;
}
.cookie-modal__title {
  margin: 0;
  color: #2988c8;
  font-size: 24px;
}
.cookie-modal__description {
  font-weight: 300;
}
.cookie-modal__footer .btn {
  border-radius: 32px;
}