@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.main-header {
  position: fixed;
  top: 0;
  width: 100%;
  // background-color: #fff;
  z-index: 10;
  height: $navbar-mobile-height;
  @media (min-width: 992px) {
    height: $navbar-height;
  }

  .main-nav {
    transition: background-color 100ms ease-in-out;
    // background-color: transparent;
    background-color: rgba(255, 255, 255, 0.65);
    // &.white-bg {
    //   background-color: #fff;
    // }
  }

  .navbar {
    flex: 1;
    padding: 12px 12px;
    @media (min-width: 992px) {
      align-items: center;
      // padding: 16px 0;
      padding: 16px 16px;
    }

    // &.color-changed {
    // background-color: #fff;
    // }
  }

  .brand-logo {
    max-width: 128px;
    @media (min-width: 992px) {
      max-width: 256px;
    }
  }

  .navbar-nav {
    @media (max-width: 991px) {
      align-items: flex-start;

      overflow-y: auto;
      height: calc(100vh - 64px);
    }

    @media (min-width: 992px) {
      justify-content: space-between;
    }

    .nav-link {
      color: $text-color;
      font-size: 20px;
      // font-weight: 300;
    }
  }

  .nav-item {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;

    @media (min-width: 992px) {
      flex: 1;
    }

    ul.wrapper {
      @media (min-width: 992px) {
        display: none;
      }

      > li.active {
        border: 1px solid $primary;
        border-radius: 16px;
        padding: 0 8px;
      }

      a {
        color: $text-color;
        text-decoration: none;
      }
    }

    &:hover {
      ul.wrapper {
        @media (min-width: 992px) {
          position: absolute;
          top: 100%;
          padding-top: 16px;
          display: flex;
          flex-flow: row nowrap;
          white-space: nowrap;
        }
      }
    }

    &.active {
      &::after {
        content: '';
        position: absolute;
        // left: calc(-50% + );
        // bottom: -2px;
        width: calc(100%);
        height: 3px;
        background-color: $danger;
        // border-radius: 8px;
      }

      .nav-link {
        color: #22598f;
      }
    }

    .nav-link {
      font-weight: 500;
      padding: 4px;
      @media (min-width: 992px) {
        padding: 4px 1rem;
        // text-align: center;
      }

      * {
        pointer-events: none;
      }

      &.active {
        .nav-link-active {
          visibility: visible;
        }
      }

      .nav-link-active {
        display: none;
        visibility: hidden;
        // padding-right: $navbar-nav-link-padding-x;
        // padding-left: $navbar-nav-link-padding-x;

        @media (min-width: 992px) {
          display: block;
        }
      }
    }
  }

  .sub-nav {
    .wrapper {
      display: flex;
      flex-flow: row nowrap;

      & > * {
        margin-right: 24px;
      }
    }
  }

  @media (min-width: 992px) {
    .nav-sub-item {
      ul {
        display: none !important;
      }

      &:hover {
        ul {
          display: flex !important;
        }
      }
    }
  }

  .search-input {
    position: relative;
    display: none;
    @media (min-width: map-get($grid-breakpoints, md)) {
      display: block;
    }

    input {
      height: 48px;
      width: 207px;
      padding: 16px;
      padding-right: 40px;
      margin: 8px 0;
      background-color: #fff;
      border: none;
      border-radius: 48px;
      font-weight: 300;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    }

    &::after {
      content: '\f002';
      position: absolute;
      color: #999;
      font-family: 'FontAwesome';
      right: 16px;
      top: 18px;
    }
  }

  .nav-search {
    // display: none;
    display: flex;
    align-items: center;
    @media (min-width: 992px) {
      display: flex;
    }

    &__mobile-search-icon {
      margin-right: 8px;
      display: block;
      color: #999;
      font-size: 24px;
      @media (min-width: map-get($grid-breakpoints, md)) {
        display: none;
      }
    }

    &__inquiry {
      display: none;
      margin-left: 16px;
      @media (min-width: map-get($grid-breakpoints, md)) {
        display: block;
      }
    }
    &__inquiry-icon {
      margin-left: 8px;
      max-width: 24px;
    }
  }

  //
  .animated-icon1 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      background: #4e4e4e;

      &:nth-child(1) {
        // background: #1e345a;
        top: 0;
      }

      &:nth-child(2) {
        // background: #199bd7;
        top: 10px;
      }

      &:nth-child(3) {
        // background: #f6921e;
        top: 20px;
      }
    }
  }

  .language-bar {
    height: 32px;
    padding: 4px 0;
    color: #fff;
    background-color: #4e4e4e;

    &__user {
      cursor: pointer;
    }
    &__divider {
      margin-left: 16px;
      color: #fff;
    }
    &__language {
      margin-left: 16px;
      a {
        color: #fff;
      }
    }

    .container-fluid {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width: map-get($grid-breakpoints, sm)) {
        justify-content: flex-end;
      }
    }
  }
}

.side-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -320px;
  width: 320px;
  padding: 32px;
  color: #fff;
  background-color: rgba(78, 78, 78, 0.93);
  z-index: 999;
  transition: left 0;
  @media (min-width: map-get($grid-breakpoints, sm)) {
    padding: 48px;
    left: -480px;
    width: 480px;
  }
  // &.collapse.show {
  &--open {
    left: 0;
    transition: left 0.1s ease-in-out;
  }

  &__close {
    font-size: 32px;
    text-align: right;
  }
  &__close-btn {
    cursor: pointer;
  }

  &__title {
    margin-top: 40px;
    color: $secondary;
    font-size: 32px;
  }

  .nav-item.nav-item-link {
    border-bottom: 2px solid #a2a2a2;
    &.active {
      border-bottom: 4px solid $secondary;
    }
  }

  .nav-item {
    margin-top: 24px;
    @media (min-width: map-get($grid-breakpoints, sm)) {
      margin-top: 32px;
    }

    .nav-link {
      font-size: 20px;
      color: #fff;
      @media (min-width: map-get($grid-breakpoints, sm)) {
        font-size: 24px;
      }
    }
  }

  &__footer {
    margin-top: 52px;
    text-align: center;

    .social {
      display: flex;
      justify-content: center;
      a {
        color: #fff;
        padding: 14px;
      }
    }
  }
}

.advice-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -320px;
  width: 320px;
  padding: 32px;
  color: #fff;
  background-color: $secondary;
  z-index: 999;
  transition: right 0;
  @media (min-width: map-get($grid-breakpoints, sm)) {
    padding: 48px;
    right: -480px;
    width: 480px;
  }
  // &.collapse.show {
  &--open {
    right: 0;
    transition: right 0.1s ease-in-out;
  }

  &__toggle-btn {
    cursor: pointer;
  }

  &__close {
    font-size: 32px;
    text-align: left;
  }
  &__close-btn {
    cursor: pointer;
  }

  &__title {
    margin-top: 40px;
    color: #fff;
    font-size: 32px;
    text-align: center;
  }
  .contact-form {
    margin-top: 40px;

    > *:not(:first-child) {
      margin-top: 24px;
      @media (min-width: map-get($grid-breakpoints, sm)) {
        margin-top: 32px;
      }
    }

    input[type='text'] {
      padding: 12px 0;
      width: 100%;
      color: #333;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #fff;
      border-radius: 0;
      outline: none;
      font-size: 20px;
      @media (min-width: map-get($grid-breakpoints, sm)) {
        font-size: 24px;
      }
      &::placeholder {
        color: #fff;
        // opacity: 0.5;
      }
    }

    button {
      height: 54px;
      max-width: 246px;
      border-radius: 54px;
    }

    .send-btn {
      font-size: 20px;
    }
  }

  &__footer {
    margin-top: 52px;
    color: #333;
    text-align: center;
  }
}
