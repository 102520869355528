.review {
  margin-top: 80px;
}
.review__figure {
  height: 100%;
  min-height: 480px;
  background-color: #fafafa;
  background-image: url(../images/review.jpg);
  background-position: center;
  background-size: cover;
}
.review__label {
  margin: 0 -15px;
  padding: 8px 32px;
  color: #fff;
  background-color: #2988c8;
  font-size: 30px;
  text-transform: uppercase;
}
.review__info__title {
  padding: 48px;
  font-size: 46px;
}
.review__info__description {
  padding: 48px;
  font-size: 28px;
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}