@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

$icon-bg: #1ac3fa !default;
$bullets: #4fc5f5 !default;

.home-content {
  // padding-top: 56px;
  // padding-bottom: 32px;

  @media (min-width: 992px) {
    // padding-top: 100px;
    // padding-bottom: 32px;
  }

  section {
    position: relative;
  }

  .section {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    //
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__icon {
      width: 80px;
      @media (min-width: 922px) {
        width: 150px;
      }
    }

    &__title {
      margin-top: 16px;
      color: #fff;
      font-size: 24px;
      text-align: center;
      @media (min-width: 922px) {
        font-size: 50px;
      }
    }

    &__subtitle {
      margin: 0;
      color: #fff;
      font-size: 24px;
    }

    //

    &.section--1 {
      background-image: url(../images/fig-1.jpg);
    }
    &.section--2 {
      background-image: url(../images/fig-2.jpg);
    }
    &.section--3 {
      background-image: url(../images/fig-3.jpg);
    }
    &.section--4 {
      background-image: url(../images/fig-4.jpg);
    }
    &.section--5 {
      background-image: url(../images/fig-5.jpg);
    }
  }

  .contact-area {
    position: relative;
    top: 0;
    right: 60%;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    padding: 24px;

    @media (min-width: 992px) {
      position: absolute;
      font-size: 22px;
      padding: 0;
    }
  }

  .footer-contact {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px 0;

    & > * {
      padding: 0 12px;
      margin: 4px 0;
      line-height: 1;
      white-space: nowrap;

      &:not(:last-child) {
        border-right: 1px solid #008dd1;
      }
    }
  }
}
