@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.insurances {
  position: relative;
  margin: -40px 0;

  &__inner {
    padding: 40px 0;
    overflow: hidden;
  }

  .insurance {
    height: 100%;
    position: relative;
    transition: transform ease-in-out 0.25s;
    transform: scale(1);
    z-index: 1;

    &:hover {
      transform: scale(1.2);
      // transform-origin: top right;
      z-index: 2;

      .info {
        background-color: #f4812e;

        .subtitle {
          display: block;
        }
      }
    }

    .figure {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 24px 40px;
      color: #fff;
      background-color: rgba(41, 136, 200, 0.86);
      font-size: 24px;
      line-height: 1.2;
      z-index: 1;

      .subtitle {
        display: none;
        margin-top: 4px;
        font-family: $reading-font;
        font-size: 16px;
      }
    }
  }
}
