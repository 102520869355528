.insurance-page {
  margin-top: 100px;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 992px) {
  .insurance-page {
    margin-top: 140px;
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
.insurance-page section {
  position: relative;
}
.insurance-page .title {
  color: #4e4e4e;
  font-size: 32px;
}
.insurance-page .type {
  padding: 8px 24px;
  color: #fff;
  background-color: #2988c8;
  text-align: center;
}
.insurance-page .subtitle {
  margin-top: 24px;
  color: #2988c8;
  font-size: 24px;
}
.insurance-page .detail {
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.insurance-page .figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.insurance-page .insurance-gauges {
  margin-top: 40px;
}