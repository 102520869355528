@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.about-us-page {
  margin-top: $navbar-mobile-height;
  padding-top: 24px;
  padding-bottom: 24px;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: $navbar-height;
    padding-top: 52px;
    padding-bottom: 52px;
  }

  section {
    position: relative;
  }

  .title {
    color: #4e4e4e;
    font-size: 32px;
    font-weight: normal;
  }

  .type {
    padding: 8px 24px;
    min-height: 63px;
    color: #fff;
    background-color: $primary;
    text-align: center;
  }

  .subtitle {
    margin-top: 24px;
    color: $primary;
    font-size: 24px;
  }

  .detail {
    font-family: $reading-font;
  }

  .figure {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .managers {
    margin-top: 32px;
    padding-top: 32px;
  }

  .roadmap-wrapper {
    margin-top: 48px;
  }
}
