@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.promotions {
  padding: 100px 0;
  background-color: #fafafa;
  background-image: url(../images/promotion.jpg);
  background-position: center;
  background-size: cover;
  overflow-x: hidden;

  &__title {
    color: #fff;
    font-size: 44px;
  }

  .promotions-slide {
    margin-top: 8px;
    margin-left: -32px;
    margin-right: -32px;
  }

  .promotion {
    margin: 32px 32px;
    display: flex;
    background-color: #fff;
    border-radius: 32px;
    box-shadow: 0 6px 26px rgba(0, 0, 0, 0.16);

    .info {
      display: flex;
      flex-direction: column;
      padding: 48px;
      flex: 0 0 400px;

      &__title {
        font-size: 24px;
      }
      &__subtitle {
        margin-top: 16px;
        color: #2988c8;
        font-size: 22px;
        line-height: 1.2;
      }
      &__description {
        margin-top: 16px;
        font-size: 18px;
        font-family: $reading-font;
        flex: 1 1 auto;
      }
      &__action {
        flex: 0 0;
      }
    }

    .figure {
      padding: 0 48px 48px 0;
    }
  }
}
