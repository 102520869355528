@charset "UTF-8";
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/mixins';

.articles {
  padding: 80px 0;
  overflow-x: hidden;

  &__title {
    font-size: 44px;
    text-align: center;
  }

  .articles-slide {
    margin-top: 48px;

    .article {
      margin: 0 24px;

      .figure {
      }

      &__info {
        &__title {
          margin-top: 24px;
          font-size: 24px;
          font-weight: 500;
        }
        &__description {
          margin-top: 16px;
          font-family: $reading-font;
        }
        &__action {
          margin-top: 24px;
          // color: $secondary;
        }
      }
    }
  }
}
