.milestone {
  position: relative;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  .milestone {
    padding-top: 96px;
  }
}
.milestone::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  border: 1px solid #c4c4c4;
}
.milestone__1 {
  margin-top: 24px;
  margin-right: 24px;
  font-size: 14px;
  text-align: right;
  flex: 1;
}
@media (min-width: 768px) {
  .milestone__1 {
    margin-top: 60px;
    font-size: 20px;
    order: 1;
  }
}
.milestone__2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-basis: 100%;
  order: -1;
}
@media (min-width: 768px) {
  .milestone__2 {
    flex-basis: auto;
    order: 2;
  }
}
.milestone__2 .milestone__circle {
  margin: 0 auto;
  width: 128px;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2988c8;
  border-radius: 128px;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 768px) {
  .milestone__2 .milestone__circle {
    width: 193px;
    height: 193px;
    border-radius: 193px;
  }
}
.milestone__2 .milestone__circle--color-1 {
  background-color: #2988c8;
}
.milestone__2 .milestone__circle--color-2 {
  background-color: #f4812e;
}
.milestone__2 .milestone__circle .title {
  margin: 0;
  color: #fff;
  font-size: 32px;
  text-align: center;
}
@media (min-width: 768px) {
  .milestone__2 .milestone__circle .title {
    font-size: 48px;
  }
}
.milestone__2 .milestone__circle .subtitle {
  margin: 0;
  color: #fff;
  font-size: 24px;
  text-align: center;
}
@media (min-width: 768px) {
  .milestone__2 .milestone__circle .subtitle {
    font-size: 32px;
  }
}
.milestone__3 {
  margin-top: 24px;
  margin-left: 24px;
  font-size: 14px;
  text-align: left;
  flex: 1;
}
@media (min-width: 768px) {
  .milestone__3 {
    margin-top: 60px;
    font-size: 20px;
    order: 3;
  }
}
.milestone-start {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  background-color: #c4c4c4;
  border-radius: 32px;
  overflow: hidden;
}
.milestone-stop {
  margin: 0 auto;
  margin-top: 24px;
  width: 32px;
  height: 32px;
  background-color: #c4c4c4;
  border-radius: 32px;
  overflow: hidden;
}
@media (min-width: 576px) {
  .milestone-stop {
    margin-top: 96px;
  }
}
.milestone-stop::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  border: 1px solid #c4c4c4;
}