.about-us-page {
  margin-top: 100px;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 992px) {
  .about-us-page {
    margin-top: 140px;
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
.about-us-page section {
  position: relative;
}
.about-us-page .title {
  color: #4e4e4e;
  font-size: 32px;
  font-weight: normal;
}
.about-us-page .type {
  padding: 8px 24px;
  min-height: 63px;
  color: #fff;
  background-color: #2988c8;
  text-align: center;
}
.about-us-page .subtitle {
  margin-top: 24px;
  color: #2988c8;
  font-size: 24px;
}
.about-us-page .detail {
  font-family: Sarabun, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.about-us-page .figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-us-page .managers {
  margin-top: 32px;
  padding-top: 32px;
}
.about-us-page .roadmap-wrapper {
  margin-top: 48px;
}